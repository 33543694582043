type MenuItemProps = {
    title: string;
    ariaLabel?: string;
    dataLabel?: string;
    rel?: string;
    target?: string;
    seoTitle?: string;
};
type SubItems = { subItems: Array<MenuItemType> };
type Link = { link: string; isNextLink?: boolean };

export type MenuItemType = MenuItemProps & (SubItems | Link);
type MenuItemTypeWithSubItems = MenuItemProps & SubItems;

export const isMenuItemWithSubItems = (
    item: MenuItemType
): item is MenuItemTypeWithSubItems => item.hasOwnProperty("subItems");
