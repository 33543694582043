import type { CountryType, Nullable } from "../../../@types/generic";
import reviewMap from "../../../app/common/skiAreaReviewMapping";
import { fetcher } from "../index";
import type { SkiArea } from "../types/skiArea";

export const getSkiArea = (
    id: string | undefined,
    country: CountryType
): Promise<Nullable<SkiArea>> => {
    if (!id) {
        return Promise.resolve(null);
    }

    return fetcher<SkiArea>({ path: `/ski-areas/${id}` }, { country })
        .then((data) => {
            data.reviews = data.reviews.sort(
                (a, b) =>
                    Object.keys(reviewMap).indexOf(a.type) -
                    Object.keys(reviewMap).indexOf(b.type)
            );

            return data;
        })
        .catch(() => null);
};
