import useSWR, { type SWRResponse } from "swr";
import type { Nullable } from "../../../@types/generic";
import { useCountry } from "../../../components/ApplicationState/hooks";
import { numberToString } from "../../../helpers/formatters/values";
import { QUERY_NAME_SKI_AREA } from "../../cacheKeys";
import { getSkiArea } from "../api/skiArea";
import type { SkiArea } from "../types/skiArea";

export const useSkiArea = ({
    id,
    enabled = true,
}: {
    id: Nullable<string> | undefined;
    enabled?: boolean;
}): SWRResponse<Nullable<SkiArea>> => {
    const country = useCountry();
    const { data, isLoading, ...rest } = useSWR(
        enabled && numberToString(id) !== "" ? [QUERY_NAME_SKI_AREA, id] : null,
        () => getSkiArea(id as string, country)
    );
    return {
        data,
        isLoading: isLoading || data === undefined,
        ...rest,
    };
};
