"use client";

import React, { useEffect, useState } from "react";
import { getWindow } from "../../helpers/getWindow";
import { useCountry } from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import { Lightbox } from "../Lightbox";
import { MenuContent } from "./content";
import styles from "./index.module.scss";
import type { MenuItemType } from "./types";

export const Menu: React.FC = () => {
    const window = getWindow();
    const [isOpen, setIsOpen] = useState(false);
    const country = useCountry();
    const [menuConfig, setMenuConfig] = useState<Array<MenuItemType>>([]);

    useEffect(() => {
        if (menuConfig.length === 0) {
            const fetchMenuConfig = async (): Promise<void> => {
                const menuConfig = (await import(`./config.${country}`).then(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
                    (mod) => mod.default
                )) as Array<MenuItemType>;
                setMenuConfig(menuConfig);
            };

            void fetchMenuConfig();
        }
    }, [country, menuConfig.length]);

    try {
        const lightboxCallback = (): void => {
            window?.requestAnimationFrame(() => {
                setIsOpen(!isOpen);
            });
        };

        return (
            <>
                <button className={styles.button} onClick={lightboxCallback}>
                    <span className="icon-menu" />
                </button>
                {isOpen ? (
                    <Lightbox
                        callback={lightboxCallback}
                        zIndexKey="menu"
                        isFullscreen={country === "de" ? false : true}
                        backdropColor="white"
                        closeIconColor="blueDark"
                        trackPageImpression="none"
                        triggerAdReload="none"
                    >
                        <MenuContent
                            items={menuConfig}
                            closeMenu={lightboxCallback}
                        />
                    </Lightbox>
                ) : (
                    <MenuContent
                        items={menuConfig}
                        closeMenu={() => undefined}
                        isSSR
                    />
                )}
            </>
        );
    } catch (error) {
        sendError(error);
        return null;
    }
};
