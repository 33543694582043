import type { CountryType, Nullable } from "../../../@types/generic";
import { fetcher } from "../index";
import type { Livecam } from "../types/livecam";

export const getLivecam = (
    id: string | undefined,
    country: CountryType
): Promise<Nullable<Livecam>> => {
    if (!id) {
        return Promise.resolve(null);
    }
    return fetcher<Livecam>(
        {
            path: `/livecams/${id}/`,
        },
        {
            country,
        }
    ).catch(() => null);
};
