import { useLayoutEffect, useState } from "react";
import type { Pushwoosh } from "web-push-notifications";
import times from "../../app/common/times";
import { pushDataLayer } from "../../helpers/dataLayer";
import { getWindow } from "../../helpers/getWindow";

type NotificationOverlayHook = {
    isVisible: boolean;
    toggleOverlay: () => void;
};

export const useNotificationOverlay = (): NotificationOverlayHook => {
    const window = getWindow();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const toggleOverlay = (): void => {
        window?.requestAnimationFrame(() => {
            setIsVisible(!isVisible);
        });
        if (!isVisible) {
            pushDataLayer({
                type: "event",
                event: "ae~push",
                eventCategory: "function",
                eventAction: "push~openLayer",
                eventLabel: "push",
            });
        }
    };
    return {
        isVisible,
        toggleOverlay,
    };
};

type UsePushwooshHook = {
    toggleSubscribe: () => void;
    permissionDenied: boolean;
    isSubscribed: boolean;
    updateTag: (
        tagName: string,
        operation: "append" | "remove",
        value: Array<string>
    ) => void;
    isInit: boolean;
    pushwooshInstance: Pushwoosh | undefined;
};

export const usePushwoosh = (): UsePushwooshHook => {
    const window = getWindow();
    const [pushwooshInstance, setPushwooshInstance] = useState<Pushwoosh>();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const permissionDenied = !(
        window &&
        "Notification" in window &&
        // eslint-disable-next-line compat/compat
        ["granted", "default"].includes(Notification.permission)
    );

    const toggleSubscribe = (): void => {
        if (pushwooshInstance && !permissionDenied) {
            if (!isSubscribed) {
                void pushwooshInstance.subscribe().then(() => {
                    pushDataLayer({
                        type: "event",
                        event: "ae~push",
                        eventCategory: "function",
                        eventAction: "push~subscribe",
                        eventLabel: "push",
                    });
                    setIsSubscribed(!isSubscribed);
                    pushwooshInstance.push((api) => {
                        void api.postEvent("web_registration", {});
                    });
                });
            } else {
                void pushwooshInstance.unsubscribe().then(() => {
                    setIsSubscribed(!isSubscribed);
                    pushDataLayer({
                        type: "event",
                        event: "ae~push",
                        eventCategory: "function",
                        eventAction: "push~unsubscribe",
                        eventLabel: "push",
                    });
                });
            }
        }
    };

    const updateTag = (
        tagName: string,
        operation: "append" | "remove",
        value: Array<string>
    ): void => {
        pushwooshInstance?.push((api) => {
            void api.setTags({
                [tagName]: {
                    operation,
                    value,
                },
            });
        });
    };

    useLayoutEffect(() => {
        void (async (): Promise<void> => {
            if (!pushwooshInstance) {
                const pwInstance = await import("web-push-notifications").then(
                    (mod) => new mod.Pushwoosh()
                );
                setPushwooshInstance(pwInstance);

                pwInstance.push([
                    "init",
                    {
                        logLevel: "error",
                        applicationCode:
                            process.env.NEXT_PUBLIC_PUSHWOOSH_PROJECT_ID ?? "",
                        safariWebsitePushID:
                            process.env.NEXT_PUBLIC_PUSHWOOSH_SAFARI_ID ?? "",
                        defaultNotificationTitle: "wetter.com",
                        autoSubscribe: false,
                        subscribeWidget: {
                            enable: false,
                        },
                        serviceWorkerUrl: "/react/service-worker/pushwoosh.js",
                    },
                ]);
                setIsInit(true);

                pwInstance.push([
                    "onSubscribe",
                    (): void => {
                        pushDataLayer({
                            type: "push",
                            push: "subscribe",
                        });
                        setIsSubscribed(true);
                    },
                ]);

                pwInstance.push([
                    "onUnsubscribe",
                    (): void => {
                        pushDataLayer({
                            type: "push",
                            push: "unsubscribe",
                        });
                        setIsSubscribed(false);
                    },
                ]);
                pwInstance.push([
                    "onPermissionDenied",
                    (): void => setIsSubscribed(false),
                ]);

                pwInstance.push([
                    "onPermissionGranted",
                    (): void => setIsSubscribed(true),
                ]);
                pwInstance.push([
                    "onReady",
                    (): void => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        pwInstance
                            .isSubscribed()
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            .then((isSubscribed: boolean) => {
                                setIsSubscribed(isSubscribed);
                                pushDataLayer({
                                    type: "push",
                                    push: isSubscribed
                                        ? "subscribe"
                                        : "unsubscribe",
                                });
                            });
                    },
                ]);
            }
        })();

        // abort if pw init fails
        window?.setTimeout(() => setIsInit(true), times["15_Seconds"]);
    }, [window, isInit, pushwooshInstance]);

    return {
        toggleSubscribe,
        permissionDenied,
        isSubscribed,
        updateTag,
        isInit,
        pushwooshInstance,
    };
};
