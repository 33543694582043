import React from "react";
import { cn } from "../../helpers/classNames";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./styles.module.scss";

type Props = {
    variant: "primary" | "secondary" | "tertiary" | "snowPrimary";
    isActive?: boolean;
    children: React.ReactNode;
    className?: string;
    dataRole?: string;
    dataAriaLabel?: string;
    dataLabel?: string;
    id?: string;
    onClick?: React.MouseEventHandler;
    fullWidth?: boolean;
};

export const Button: React.FC<Props> = ({
    variant,
    children,
    onClick,
    className,
    dataRole = undefined,
    dataAriaLabel = undefined,
    fullWidth = false,
    dataLabel,
    id,
}) => {
    try {
        return (
            <button
                className={cn(
                    styles.button,
                    styles[variant],
                    fullWidth && styles.fullWidth,
                    className
                )}
                onClick={onClick}
                tabIndex={0}
                data-role={dataRole}
                data-aria-label={dataAriaLabel}
                data-label={dataLabel}
                id={id}
            >
                {children}
            </button>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
