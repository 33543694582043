"use client";

import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import { defaultCities } from "../../../app/common/config";
import { cn } from "../../../helpers/classNames";
import { debug } from "../../../helpers/debug";
import { getWindow } from "../../../helpers/getWindow";
import { getRoute } from "../../../helpers/urls/getRoute";
import { isSpecialCam } from "../../../network/matlocq/helpers/livecams";
import { useLivecam } from "../../../network/matlocq/queries/Livecam";
import { useSkiArea } from "../../../network/matlocq/queries/SkiArea";
import { useStickyAdOffsets } from "../../Ad/hooks";
import { AdSlot } from "../../Ad/slots";
import {
    useCountry,
    usePageParams,
    usePageType,
} from "../../ApplicationState/hooks";
import { AutoSuggest } from "../../AutoSuggest";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { ErrorBoundary } from "../../ErrorBoundary";
import { sendError } from "../../ErrorBoundary/helpers";
import { Menu } from "../../Menu";
import { useNotificationOverlay } from "../../Notifications/hooks";
import { WebsiteLogo } from "../../WebsiteLogo";
import { useHeaderScroll } from "../hooks";
import styles from "./index.module.scss";
import { Magnifier } from "./magnifier";

const Overlay = dynamic(
    () => import("../../Notifications/Overlay").then((mod) => mod.Overlay),
    { ssr: false }
);

const Favorites = dynamic(
    () => import("../../Favorites").then((mod) => mod.Favorites),
    { ssr: false }
);

export const Header: React.FC = () => {
    const window = getWindow();
    const country = useCountry();
    const pageType = usePageType();
    const pageParams = usePageParams();
    const { data: livecam } = useLivecam(
        pageType?.isLivecamDetailPage && typeof pageParams.id === "string"
            ? pageParams.id
            : null
    );
    const { data: skiArea } = useSkiArea({
        id: typeof pageParams.id === "string" ? pageParams.id : null,
        enabled: pageType?.isAnySkiAreaDetailPage,
    });
    const consent = useConsent();
    const isPay = useIsPay();
    const { isVisible, toggleOverlay } = useNotificationOverlay();
    const innerTopRef = useRef<HTMLDivElement>(null);
    const { top } = useStickyAdOffsets();
    const [isShowroom, setIsShowroom] = useState(false);
    const query = useSearchParams();
    useHeaderScroll({ ref: innerTopRef, styles });

    useEffect(() => {
        if (
            (window &&
                window.sessionStorage.getItem("wcom_cp_showroom") === "true") ||
            query.get("cpShowroom") === "true"
        ) {
            setIsShowroom(true);
            // eslint-disable-next-line testing-library/no-debugging-utils
            debug(
                "[CP] Programmatic showroom is active. Close tab or clear session storage to reset."
            );
        }
    }, [window, query]);

    try {
        const hideToolbar =
            pageType?.isAnyLivecamPage || skiArea?.sponsoring.isSponsored;
        const hideAds =
            (pageType?.isLivecamDetailPage &&
                (country !== "de" || (livecam && isSpecialCam(livecam)))) ||
            skiArea?.sponsoring.isSponsored;
        const hasPushSupport = window && "PushManager" in window;

        const pollenLink =
            country === "de"
                ? getRoute({
                      parameters: {
                          pageName: "healthPollenLandingpage",
                      },
                      country,
                  })
                : getRoute({
                      parameters: {
                          pageName: "healthPollenLocationPage",
                          code: defaultCities[country].code,
                          seoString: defaultCities[country].seoString,
                      },
                      country,
                  });

        return (
            <header>
                <div
                    className={styles.outer}
                    style={{
                        paddingTop: country === "at" || hideAds ? 0 : top,
                    }}
                >
                    {/* reserve height to have always enough space for header */}
                    <div className={styles.innerTopSpacer} />
                    <div
                        className={styles.innerTop}
                        style={{ top: country === "at" || hideAds ? 0 : top }}
                        ref={innerTopRef}
                    >
                        <WebsiteLogo />
                        <div className={styles.innerRight}>
                            {isShowroom ? (
                                <span
                                    className={cn(
                                        styles.programmaticStatus,
                                        "icon-house-cash"
                                    )}
                                    title="Programmatic showroom is active. Close tab or clear session storage to reset."
                                />
                            ) : null}
                            <Magnifier />
                            {consent && !isPay && hasPushSupport ? (
                                <ErrorBoundary>
                                    <div
                                        className={styles.wrapperBell}
                                        onClick={toggleOverlay}
                                        role="button"
                                        tabIndex={0}
                                        id="notification-bell"
                                        aria-label="Benachrichtigungseinstellungen öffnen"
                                    >
                                        <span className="icon-bell" />
                                        <span
                                            className={cn(
                                                styles.bellDot,
                                                "bell-dot"
                                            )}
                                        />
                                    </div>
                                    {isVisible ? (
                                        <Overlay
                                            toggleOverlay={toggleOverlay}
                                        />
                                    ) : null}
                                </ErrorBoundary>
                            ) : null}
                            <a
                                className={styles.wrapperOutline}
                                href={pollenLink}
                                target="_self"
                                aria-label="Pollenflug"
                                data-label="Pollenflug"
                                title="Infos zum Pollenflug in Deiner Region"
                            >
                                <span className="icon-polle" />
                            </a>
                            {/*<NextLink*/}
                            {/*    className={styles.wrapperOutline}*/}
                            {/*    href={getRoute({*/}
                            {/*        pageName: "skiAreaList",*/}
                            {/*    })}*/}
                            {/*    target="_self"*/}
                            {/*    aria-label="Skigebiete öffnen"*/}
                            {/*    data-label="Skigebiete öffnen"*/}
                            {/*    title="Alle Infos zu Wetter, Neuschnee, Pisten, Lifte sowie Skipasspreise - Wetter ist wetter.com"*/}
                            {/*>*/}
                            {/*    <span className="snow-icon-skiarea" />*/}
                            {/*</NextLink>*/}
                            <ErrorBoundary>
                                <Menu />
                            </ErrorBoundary>
                        </div>
                    </div>
                    <div
                        className={
                            pageType?.isAnySkiAreasPage
                                ? styles.innerBottomSkiArea
                                : styles.innerBottom
                        }
                    >
                        {hideToolbar ? null : (
                            <AdSlot
                                slot={{
                                    de: "wetter_direct_toolbar",
                                    at: "wetter_direct_toolbar",
                                    ch: "wetter_direct_toolbar",
                                    pl: undefined,
                                }}
                                minHeight="0"
                            />
                        )}
                        {!pageType?.isPlainPage && (
                            <>
                                <ErrorBoundary>
                                    <AutoSuggest />
                                </ErrorBoundary>
                                {pageType?.isHomePage ? (
                                    <div className={styles.wrapperFavorites}>
                                        <ErrorBoundary>
                                            <Favorites />
                                        </ErrorBoundary>
                                    </div>
                                ) : null}
                            </>
                        )}
                        {hideAds ? null : (
                            <AdSlot
                                slot={{
                                    de: undefined,
                                    at: "mbanner1",
                                    ch: undefined,
                                    pl: undefined,
                                }}
                                minHeight="170px"
                            />
                        )}
                    </div>
                </div>
            </header>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
