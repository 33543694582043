import type { SkiAreaReviewType } from "../../network/matlocq/types/skiArea";

const reviewMap: Record<SkiAreaReviewType, { text: string; icon: string }> = {
    freestyle: { text: "Freestyle", icon: "snow-icon-freestyle" },
    families: { text: "Familie", icon: "snow-icon-family2" },
    onMountainDining: { text: "Hütten", icon: "snow-icon-house" },
    beginner: { text: "Anfänger", icon: "snow-icon-newbie" },
    freeride: { text: "Freeride", icon: "snow-icon-freeride" },
    apres: { text: "Aprés Ski", icon: "snow-icon-apres-ski" },
    snow: { text: "Schneesicherheit", icon: "snow-icon-snow" },
};

export default reviewMap;
