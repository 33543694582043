"use client";

import React, { useLayoutEffect, useRef, useState } from "react";
import type { Nullable } from "../../@types/generic";
import { cn } from "../../helpers/classNames";
import { pushDataLayer } from "../../helpers/dataLayer";
import { getWindow } from "../../helpers/getWindow";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./styles.module.scss";

const thresholdId = "show_scroll_top_btn";
export const ScrollToTopThreshold: React.FC = () => <div id={thresholdId} />;

export const ScrollToTop: React.FC = () => {
    const window = getWindow();
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [thresholdElement, setThresholdElement] =
        useState<Nullable<HTMLElement>>();
    const observerEntry = useIntersectionObserver(thresholdElement, {});

    useLayoutEffect(() => {
        setThresholdElement(window?.document.getElementById(thresholdId));
    }, [window?.document]);

    useLayoutEffect(() => {
        if (observerEntry) {
            if (observerEntry.isIntersecting) {
                setIsVisible(true);
            } else if (observerEntry.boundingClientRect.y > 0) {
                setIsVisible(false);
            }
        }
    }, [observerEntry]);

    try {
        const scrollToTop = (): void => {
            window?.scroll({ top: 0, left: 0, behavior: "smooth" });
            pushDataLayer({
                type: "event",
                event: "clicktrack",
                eventCategory: "function",
                eventAction: "click",
                eventLabel: "scroll_to_top",
            });
        };

        if (!thresholdElement) {
            return null;
        }

        return (
            <div
                className={cn(styles.element, isVisible && styles.show)}
                onClick={scrollToTop}
                ref={ref}
                role="button"
                tabIndex={0}
            >
                <span className="icon-arrow" />
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
