import { isLivecam, type Livecam } from "../../types/livecam";
import type { PlaylistItem } from "../../types/playlistItem";

const partners = ["wetter.com", "wettercomearthtv"];

export const showLivecamPartnerLogo = (item: Livecam | PlaylistItem): boolean =>
    partners.includes(
        isLivecam(item)
            ? (item.partner.name?.toLowerCase() ?? "")
            : (item.content?.partner?.name?.toLowerCase() ?? "")
    );

export const isSpecialCam = (item: Livecam | undefined): boolean =>
    item?.tags.includes("playerversion2") ?? false;

export const isLiveSpottingCam = (item: Livecam | undefined): boolean =>
    item?.partner.name?.toLowerCase() === "livespotting";
