"use client";

import NextScript from "next/script";
import React from "react";
import type { Nullable } from "../../@types/generic";
import { useConsent, useIsPay } from "../Consent/hooks";
import { sendError } from "../ErrorBoundary/helpers";

type Props = {
    script?: Nullable<string>;
};

export const AdDefend: React.FC<Props> = ({ script }) => {
    const consent = useConsent();
    const isPay = useIsPay();

    try {
        if (!consent || isPay || typeof script !== "string" || script === "") {
            return null;
        }

        return (
            <NextScript strategy="lazyOnload" id="addefend-script">
                {script}
            </NextScript>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
