"use client";

import React, { useEffect } from "react";
import { cn } from "../../helpers/classNames";
import { pushDataLayer } from "../../helpers/dataLayer";
import { getWindow } from "../../helpers/getWindow";
import type { DataLayerItemPageTrack, OverlayType } from "../../helpers/types";
import { useReloadAds, useStickyAdOffsets } from "../Ad/hooks";
import { ClickPerformanceSticky } from "../Ad/slots/ClickPerformanceSticky";
import { useCountry } from "../ApplicationState/hooks";
import { ErrorBoundary } from "../ErrorBoundary";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./index.module.scss";
import type { OverlaySetTargeting } from "./types";

const zIndexMap = {
    cmp: "--wcom-z-cmp",
    lightbox: "--wcom-z-lightbox",
    newDataUpdate: "--wcom-z-newDataUpdate",
    notifications: "--wcom-z-notifications",
    menu: "--wcom-z-menu",
};

const closeIconColorMap = {
    white: "--wcom-c-white",
    blueDark: "--wcom-c-blueDark",
    almostBlack: "--wcom-c-almostBlack",
};

const backdropColorMap = {
    blackAlpha90: "--wcom-c-blackAlpha90",
    blueDarkAlpha80: "--wcom-c-blueDarkAlpha80",
    white: "--wcom-c-white",
};

type TrackingType = "open" | "close" | "openAndClose" | "none";

type LightboxProps = {
    callback?: () => void;
    children: React.ReactNode;
    isFullscreen?: boolean;
    zIndexKey?: keyof typeof zIndexMap;
    closeIconColor?: keyof typeof closeIconColorMap;
    backdropColor?: keyof typeof backdropColorMap;
    trackPageImpression: TrackingType;
    trackOverlay?: TrackingType;
    triggerAdReload: TrackingType;
    overlaySetTargeting?: OverlaySetTargeting;
    overlayType?: OverlayType;
    additionalTrackingParams?: Pick<DataLayerItemPageTrack, "pageTypeDetail">;
};

export const Lightbox: React.FC<LightboxProps> = ({
    children,
    callback,
    isFullscreen,
    zIndexKey = "lightbox",
    closeIconColor = "white",
    backdropColor = "blueDarkAlpha80",
    trackPageImpression,
    trackOverlay,
    triggerAdReload,
    overlaySetTargeting,
    overlayType,
    additionalTrackingParams,
}) => {
    const country = useCountry();
    const window = getWindow();
    const reloadAds = useReloadAds();
    const hasPIOnOpen =
        trackPageImpression === "open" ||
        trackPageImpression === "openAndClose";
    const { bottom, top } = useStickyAdOffsets();

    // setup+teardown useEffect, don't add any other logic here nor effect deps
    useEffect(() => {
        // >>> open lightbox <<<
        if (window) {
            window.document.body.classList.add("noscroll");
        }
        if (overlayType) {
            if (hasPIOnOpen) {
                pushDataLayer({
                    type: "pageTrack",
                    event: "pagetrack",
                    overlay: "open",
                    overlayType,
                    ...additionalTrackingParams,
                });
            } else if (
                trackOverlay === "open" ||
                trackOverlay === "openAndClose"
            ) {
                pushDataLayer({
                    type: "overlay",
                    event: "overlay",
                    overlay: "open",
                    overlayType,
                });
            }
            // else no dl tracking at all
        }

        // >>> close lightbox <<<
        return (): void => {
            if (window) {
                window.document.body.classList.remove("noscroll");
            }
            if (overlayType) {
                if (
                    trackPageImpression === "close" ||
                    trackPageImpression === "openAndClose"
                ) {
                    pushDataLayer({
                        type: "pageTrack",
                        event: "pagetrack",
                        overlay: "close",
                        overlayType,
                        ...additionalTrackingParams,
                    });
                } else if (
                    trackOverlay === "close" ||
                    trackOverlay === "openAndClose"
                ) {
                    pushDataLayer({
                        type: "overlay",
                        event: "overlay",
                        overlay: "close",
                        overlayType,
                    });
                }
                // else no dl tracking at all
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (hasPIOnOpen && (country === "at" || country === "ch")) {
            const overlaySlot = window?.adHandler?.service?.getAdUnit(
                `WET_${country.toUpperCase()}_M_overlay_stickybanner`
            );
            if (overlaySlot) {
                overlaySlot.enable("adUnit");
            }
        }

        if (overlaySetTargeting) {
            // this can sometimes fail due to timeing, so we also have the setTargeting
            // with data-programmatic-component and data-programmatic-type in place.
            window?.adHandler?.service?.setTargeting(
                overlaySetTargeting,
                "open"
            );
        }

        if (
            reloadAds &&
            (triggerAdReload === "open" || triggerAdReload === "openAndClose")
        ) {
            reloadAds({
                de: [
                    "wetter_overlay_performancerectangle1",
                    "wetter_premiumstickybanner",
                ],
                at: ["WET_AT_M_overlay_stickybanner"],
                ch: ["WET_CH_M_overlay_stickybanner"],
            });
        }

        return (): void => {
            if (country === "at" || country === "ch") {
                const overlaySlot = window?.adHandler?.service?.getAdUnit(
                    `WET_${country.toUpperCase()}_M_overlay_stickybanner`
                );
                if (overlaySlot) {
                    overlaySlot.disable("adUnit");
                }
            }

            if (
                reloadAds &&
                (triggerAdReload === "close" ||
                    triggerAdReload === "openAndClose")
            ) {
                if (overlaySetTargeting) {
                    window?.adHandler?.service?.setTargeting(
                        overlaySetTargeting,
                        "close"
                    );
                }
                reloadAds();
            }
        };
    }, [
        country,
        overlaySetTargeting,
        hasPIOnOpen,
        reloadAds,
        triggerAdReload,
        window?.adHandler?.service,
    ]);

    try {
        return (
            <>
                <div
                    className={styles.wrapper}
                    role="dialog"
                    style={{
                        zIndex: `calc(var(${zIndexMap[zIndexKey]}) - 10)`,
                        top: isFullscreen ? "0" : top,
                        bottom: isFullscreen ? "0" : bottom,
                    }}
                    data-testid="backdropElement"
                    data-programmatic-component="overlay"
                    data-programmatic-type={overlaySetTargeting}
                >
                    {callback !== undefined && (
                        <span
                            className={cn(styles.close, "icon-cross")}
                            style={{
                                color: `var(${closeIconColorMap[closeIconColor]})`,
                                zIndex: `calc(var(${zIndexMap[zIndexKey]}) + 1)`,
                            }}
                            onClick={(): void => {
                                callback();
                            }}
                            role="button"
                            tabIndex={0}
                        />
                    )}
                    <ErrorBoundary>
                        <div
                            style={{
                                zIndex: `var(${zIndexMap[zIndexKey]})`,
                            }}
                        >
                            {children}
                        </div>
                    </ErrorBoundary>
                    <div
                        className={styles.backdrop}
                        style={{
                            background: `var(${backdropColorMap[backdropColor]})`,
                        }}
                        role="button"
                        tabIndex={0}
                        onClick={(): void => {
                            if (callback !== undefined) {
                                callback();
                            }
                        }}
                    />
                </div>
                {hasPIOnOpen ? (
                    <ClickPerformanceSticky
                        slot={{
                            at: "WET_AT_M_overlay_stickybanner",
                            ch: "WET_CH_M_overlay_stickybanner",
                        }}
                    />
                ) : null}
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
