"use client";

import { createInstance } from "@optimizely/optimizely-sdk";
import locale from "dayjs/locale/de";
import type React from "react";
import { useEffect, useMemo, useRef } from "react";
import { useReloadAds } from "../../components/Ad/hooks";
import {
    useCountry,
    useIsPersistingForbidden,
    usePageParams,
    usePageType,
    useSetPrebidAB,
} from "../../components/ApplicationState/hooks";
import { useConsent } from "../../components/Consent/hooks";
import { sendError } from "../../components/ErrorBoundary/helpers";
import { getWindow } from "../../helpers/getWindow";
import { useIsPageChange } from "../../hooks/useIsPageChange";
import { OPTIMIZELY_SDK_KEY } from "../common/config";
import dayjs from "../common/dayjs";

type Decision = "client" | "server" | "hybrid" | "client_fallback";
type Props = {
    children: React.ReactNode;
};

const ProvidersInner: React.FC<Props> = ({ children }) => {
    const country = useCountry();
    const consent = useConsent();
    const reloadAds = useReloadAds();
    const isPageChange = useIsPageChange();
    const setupDayjs = useRef(false);
    const window = getWindow();
    let userId = null;
    const isPersistingForbidden = useIsPersistingForbidden();
    const pageParams = usePageParams();
    const pageType = usePageType();

    // setup for WR-5557/WR-5497 A/B test prebid
    const setPrebid = useSetPrebidAB();
    const localStorageKey = "wcom_opt_wr5497_2";
    const excludeFromTest = "DE0000003"; // Aachen
    const variationsAB: Record<
        Decision,
        { url: string; tracking: Array<string> }
    > = useMemo(
        () => ({
            client_fallback: {
                url: "https://cs3.wettercomassets.com/wcomv5/js/programmatic/WET_ROSv2.js",
                tracking: ["client_fallback_a", "client_fallback_r"],
            },
            client: {
                url: "https://cs3.wettercomassets.com/wcomv5/js/programmatic/WET_ROSv2_mobile_control.js",
                tracking: ["client_a", "client_r"],
            },
            server: {
                url: "https://cs3.wettercomassets.com/wcomv5/js/programmatic/WET_ROSv2_mobile_server.js",
                tracking: ["server_a", "server_r"],
            },
            hybrid: {
                url: "https://cs3.wettercomassets.com/wcomv5/js/programmatic/WET_ROSv2_mobile_hybrid.js",
                tracking: ["hybrid_a", "hybrid_r"],
            },
        }),
        []
    );

    dayjs.locale(locale);

    useEffect(() => {
        if (isPageChange) {
            if (reloadAds) {
                reloadAds();
            }
        }
    }, [reloadAds, isPageChange]);

    useEffect(() => {
        if (consent) {
            document.cookie =
                "receive-cookie-deprecation=1; Secure; Path=/; SameSite=None; Partitioned;  Max-Age=15552000";
        }
    }, [consent]);

    useEffect(() => {
        if (setupDayjs.current) {
            return;
        }
        void (async (): Promise<void> => {
            const locale = await import("dayjs/locale/de");
            dayjs.locale(locale);
            setupDayjs.current = true;
        })();
    }, [country]);

    useEffect(() => {
        if (consent && window !== undefined && setPrebid !== null) {
            void (async (): Promise<void> => {
                try {
                    const optimizely = createInstance({
                        sdkKey: OPTIMIZELY_SDK_KEY,
                        logLevel:
                            process.env.NEXT_PUBLIC_DEPLOY_ENV === "production"
                                ? "error"
                                : "debug",
                    });
                    if (optimizely) {
                        await optimizely.onReady();
                        const user = optimizely.createUserContext(
                            window.localStorage.getItem("wcom_opt_usr") ?? "",
                            { logged_in: true }
                        );
                        if (user) {
                            // skip test is not running
                            const decisionIsRunning =
                                user.decide("wr-5497_enabled");

                            if (!decisionIsRunning.enabled) {
                                // no tracking, default FW
                                setPrebid({
                                    url: variationsAB.client_fallback.url,
                                    ab_5497_a: null,
                                    ab_5497: null,
                                });
                                return;
                            }

                            // read decision from local storage
                            const decisionFromLocalStorage =
                                (window.localStorage.getItem(localStorageKey) ??
                                    undefined) as Decision | undefined;
                            if (
                                pageParams.code === excludeFromTest &&
                                pageType?.isAnyForecastPage
                            ) {
                                // no tracking, server FW
                                setPrebid({
                                    url: variationsAB.server.url,
                                    ab_5497_a: null,
                                    ab_5497: null,
                                });
                            } else if (decisionFromLocalStorage !== undefined) {
                                // when there is already a decision in local storage, take this
                                const key: Decision = decisionFromLocalStorage;
                                setPrebid({
                                    url: variationsAB[key].url,
                                    ab_5497_a: `${key}_a`,
                                    ab_5497: `${key}_r`,
                                });
                            } else {
                                // default case in else block
                                const decisionFallback =
                                    user.decide("wr-5497_fallback");
                                const decisionHybrid =
                                    user.decide("wr-5497_hybrid");
                                const decisionServer =
                                    user.decide("wr-5497_server");
                                const decisionClient =
                                    user.decide("wr-5497_client");
                                const activeDecisions: Array<Decision> = [];

                                // when `enabled` is user in decision
                                if (decisionFallback.enabled) {
                                    activeDecisions.push("client_fallback");
                                }
                                if (decisionHybrid.enabled) {
                                    activeDecisions.push("hybrid");
                                }
                                if (decisionServer.enabled) {
                                    activeDecisions.push("server");
                                }
                                if (decisionClient.enabled) {
                                    activeDecisions.push("client");
                                }

                                // get random decision if multiple are active
                                const randomDecision: Decision | undefined =
                                    activeDecisions[
                                        Math.floor(
                                            Math.random() *
                                                activeDecisions.length
                                        )
                                    ];

                                if (randomDecision !== undefined) {
                                    const key: Decision = randomDecision;
                                    setPrebid({
                                        url: variationsAB[key].url,
                                        ab_5497_a: `${key}_a`,
                                        ab_5497: `${key}_r`,
                                    });

                                    window.localStorage.setItem(
                                        localStorageKey,
                                        key
                                    );
                                } else {
                                    // no tracking, default FW
                                    setPrebid({
                                        url: variationsAB.client_fallback.url,
                                        ab_5497_a: null,
                                        ab_5497: null,
                                    });
                                }
                            }
                        } else {
                            // no tracking, default FW
                            setPrebid({
                                url: variationsAB.client_fallback.url,
                                ab_5497_a: null,
                                ab_5497: null,
                            });
                        }
                    }
                } catch (error) {
                    // no tracking, default FW
                    setPrebid({
                        url: variationsAB.client_fallback.url,
                        ab_5497_a: null,
                        ab_5497: null,
                    });

                    sendError(error);
                }
            })();
        }
    }, [
        window,
        consent,
        pageParams.code,
        pageType?.isAnyForecastPage,
        setPrebid,
        variationsAB,
    ]);

    try {
        userId = window?.localStorage.getItem("wcom_opt_usr");
    } catch (e) {
        sendError(e);
    }

    const optimizelyUser = {
        id:
            userId ??
            window?.btoa(
                `${Math.random().toString()}_${Date.now().toString()}`
            ) ??
            "",
    };

    if (consent && !isPersistingForbidden && optimizelyUser.id) {
        window?.localStorage.setItem("wcom_opt_usr", optimizelyUser.id);
    }

    return children;
};

export default ProvidersInner;
