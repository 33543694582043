"use client";

import React from "react";
import type { Nullable } from "../../../@types/generic";
import { cn } from "../../../helpers/classNames";
import { getWindow } from "../../../helpers/getWindow";
import { sendError } from "../../ErrorBoundary/helpers";
import styles from "./magnifier.module.scss";

export const Magnifier: React.FC = () => {
    const window = getWindow();
    const autosuggestInput = window?.document.querySelector(
        "#autosuggest-container input"
    ) as Nullable<HTMLInputElement>;

    try {
        const onClickMagnifier = (): void => {
            window?.scroll({ top: 0, behavior: "smooth" });
            autosuggestInput?.focus();
        };
        return (
            <span
                className={cn(styles.wrapper, "icon-magnifier")}
                role="button"
                tabIndex={0}
                onClick={onClickMagnifier}
            />
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
