import useSWR, { type SWRResponse } from "swr";
import type { Nullable } from "../../../@types/generic";
import { useCountry } from "../../../components/ApplicationState/hooks";
import { numberToString } from "../../../helpers/formatters/values";
import { QUERY_NAME_LIVECAM_BY_ID } from "../../cacheKeys";
import { getLivecam } from "../api/livecam";
import type { Livecam } from "../types/livecam";

export const useLivecam = (
    id: Nullable<string>
): SWRResponse<Nullable<Livecam>> => {
    const country = useCountry();
    const { data, isLoading, ...rest } = useSWR<Nullable<Livecam>>(
        numberToString(id) !== "" ? [QUERY_NAME_LIVECAM_BY_ID, id] : null,
        () => getLivecam(id as string, country)
    );

    return {
        data,
        isLoading: isLoading || data === undefined,
        ...rest,
    };
};
