import React from "react";
import { MenuItem } from "./Item";
import styles from "./content.module.scss";
import type { MenuItemType } from "./types";

type Props = {
    items: Array<MenuItemType>;
    closeMenu: () => void;
    isSSR?: boolean;
};

export const MenuContent: React.FC<Props> = ({
    items,
    closeMenu,
    isSSR = false,
}) => {
    return (
        <nav className={styles.wrapper}>
            {items.map((item) => (
                <MenuItem
                    item={item}
                    key={`item-container-${item.title}`}
                    nestingLevel={0}
                    closeMenu={closeMenu}
                    isSSR={isSSR}
                />
            ))}
        </nav>
    );
};
