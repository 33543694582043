import NextLink from "next/link";
import React, { useRef, useState } from "react";
import { cn } from "../../helpers/classNames";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./item.module.scss";
import type { MenuItemType } from "./types";
import { isMenuItemWithSubItems } from "./types";

type Props = {
    item: MenuItemType;
    nestingLevel: number;
    closeMenu: () => void;
    isSSR: boolean;
};

export const MenuItem: React.FC<Props> = ({
    item,
    nestingLevel,
    closeMenu,
    isSSR,
}) => {
    const [isOpen, setIsOpen] = useState(isSSR || false);
    const ref = useRef<HTMLDivElement>(null);

    try {
        return (
            <div
                className={cn(
                    styles.wrapper,
                    nestingLevel % 2 === 0
                        ? styles.wrapperColorEven
                        : styles.wrapperColorOdd,
                    isOpen && styles.wrapperIsOpen
                )}
                role="menuitem"
                aria-haspopup={isMenuItemWithSubItems(item) ? "true" : "false"}
                ref={ref}
            >
                {isMenuItemWithSubItems(item) ? (
                    <button
                        className={styles.openerWrapper}
                        onClick={(e): false => {
                            if (isMenuItemWithSubItems(item)) {
                                // stop bubbling up to the parent
                                e.preventDefault();
                                e.stopPropagation();
                                setIsOpen(!isOpen);
                            }
                            // if opening, scroll up
                            if (!isOpen) {
                                // make sure the element is fully visible with expanded height
                                window.setTimeout(() => {
                                    ref.current?.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start",
                                    });
                                });
                            }
                            return false;
                        }}
                    >
                        {item.title}
                        <div
                            className={cn(
                                styles.opener,
                                isOpen && styles.openerOpen
                            )}
                        >
                            <span className="icon-dropdown" />
                        </div>
                    </button>
                ) : item.isNextLink ? (
                    <NextLink
                        className={styles.link}
                        href={item.link}
                        title={item.seoTitle ?? item.title}
                        data-label={item.dataLabel}
                        aria-label={item.ariaLabel}
                        target={item.target}
                        rel={item.rel}
                        onClick={closeMenu}
                    >
                        {item.title}
                    </NextLink>
                ) : (
                    <a
                        className={styles.link}
                        href={item.link}
                        title={item.seoTitle ?? item.title}
                        data-label={item.dataLabel}
                        aria-label={item.ariaLabel}
                        target={item.target}
                        rel={item.rel}
                        onClick={closeMenu}
                    >
                        {item.title}
                    </a>
                )}
                {isMenuItemWithSubItems(item) && isOpen ? (
                    <div role="menu">
                        {item.subItems.map((subItem) => (
                            <MenuItem
                                item={subItem}
                                key={`item-container-${item.title}-${subItem.title}`}
                                nestingLevel={nestingLevel + 1}
                                closeMenu={closeMenu}
                                isSSR={isSSR}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        );
    } catch (error) {
        sendError(error);
        return null;
    }
};
