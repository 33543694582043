"use client";

import React from "react";
import { cn } from "../../../helpers/classNames";
import { isSpecialCam } from "../../../network/matlocq/helpers/livecams";
import { useLivecam } from "../../../network/matlocq/queries/Livecam";
import { useSkiArea } from "../../../network/matlocq/queries/SkiArea";
import {
    useCountry,
    useIsMobileSafari,
    useIsPersistingForbidden,
    usePageParams,
    usePageType,
} from "../../ApplicationState/hooks";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import type {
    AdSlotsATClickPerformance,
    AdSlotsCHClickPerformance,
    AdSlotsDEClickPerformance,
} from "../types";
import styles from "./styles.module.scss";

type Props = {
    slot: {
        de?: Extract<AdSlotsDEClickPerformance, "wetter_premiumstickybanner">;
        at?: Extract<
            AdSlotsATClickPerformance,
            "WET_AT_M_overlay_stickybanner"
        >;
        ch?: Extract<
            AdSlotsCHClickPerformance,
            "WET_CH_M_overlay_stickybanner" | "WET_CH_M_premiumstickybanner"
        >;
    };
};

/**
 * **Note:** this component is called directly and not via Ad/slots/index.tsx.
 * The slot id is not passed and static inside the component, as it is only the "de" version
 * of the slot.
 * @returns React.FC with the sticky ad slot.
 */
export const ClickPerformanceSticky: React.FC<Props> = ({ slot }) => {
    const consent = useConsent();
    const country = useCountry();
    const isPersistingForbidden = useIsPersistingForbidden();
    const isPay = useIsPay();
    const isMobileSafari = useIsMobileSafari();
    const pageType = usePageType();
    const pageParams = usePageParams();
    const { data: livecam } = useLivecam(
        pageType?.isLivecamDetailPage && typeof pageParams.id === "string"
            ? pageParams.id
            : null
    );
    const { data: skiArea } = useSkiArea({
        id: typeof pageParams.id === "string" ? pageParams.id : null,
        enabled: pageType?.isAnySkiAreaDetailPage,
    });

    try {
        if (isPay || isPersistingForbidden || !consent) {
            return null;
        }

        const hideAds =
            (pageType?.isLivecamDetailPage &&
                livecam &&
                isSpecialCam(livecam)) ||
            (pageType?.isAnySkiAreaDetailPage &&
                skiArea &&
                skiArea.sponsoring.isSponsored);

        if (hideAds) {
            return null;
        }

        const adjustZindexToLightbox =
            country === "at" ||
            (country === "ch" && slot.ch === "WET_CH_M_overlay_stickybanner");

        if (
            (country === "de" && slot.de !== undefined) ||
            (country === "at" && slot.at !== undefined) ||
            (country === "ch" && slot.ch !== undefined)
        ) {
            return (
                <div
                    className={cn(
                        styles.adSlot,
                        styles.clickperformanceWrapper
                    )}
                >
                    <div
                        className={cn(
                            styles.stickyBanner,
                            isMobileSafari
                                ? styles.stickyBannerTop
                                : styles.stickyBannerBottom,
                            adjustZindexToLightbox &&
                                styles.stickyBannerInsideLightbox
                        )}
                        id={slot[country] ? `${slot[country]}-outer` : ""}
                        data-testid="cp-adslot"
                    >
                        <div
                            id={slot[country]}
                            className={cn(
                                styles.stickyBannerInner,
                                "ad-flex",
                                "ad-fix"
                            )}
                            data-page-type={
                                pageType?.isForecastTodayPage
                                    ? "Refresh_REACT_today"
                                    : "notset"
                            }
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    } catch (e) {
        sendError(e);
        return null;
    }
};
