import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/application/node_modules/.pnpm/next-intl@4.0.2_next@15.1.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sa_ua2a2odyuipbi7jgpjbatttt3q/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/application/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.83.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/application/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.83.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/application/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.83.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/common/fonts/index.ts\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--wcom-font\",\"fallback\":[\"system-ui\"]}],\"variableName\":\"wcomFont\"}");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/application/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.83.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/common/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/wcomIcons.woff2\",\"variable\":\"--wcom-icon-font\"}],\"variableName\":\"wcomIconFont\"}");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/application/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.83.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/common/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/snowIcons.woff2\",\"variable\":\"--wcom-snow-icon-font\"}],\"variableName\":\"wcomSnowIconFont\"}");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/application/src/app/(dach)/layout.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/application/src/app/(dach)/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClickPerformanceSticky"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/Ad/slots/ClickPerformanceSticky.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdDefend"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/AdDefend/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/ErrorBoundary/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CmpLink"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/Footer/dach/cmpLink.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/application/src/components/Footer/dach/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["GTM"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/GTM/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/Header/dach/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewDataUpdate"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/NewDataUpdate/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollToTop"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/ScrollToTop/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Slider"] */ "/opt/atlassian/pipelines/agent/build/application/src/components/Slider/index.tsx");
