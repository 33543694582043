"use client";

import { usePathname, useSearchParams } from "next/navigation";
import React, { useEffect, useRef } from "react";
import { SWRConfig } from "swr";
import { ApplicationState } from "../../components/ApplicationState";
import { ConsentProvider } from "../../components/Consent/provider";
import { sendError } from "../../components/ErrorBoundary/helpers";
import { pushDataLayer } from "../../helpers/dataLayer";
import { getWindow } from "../../helpers/getWindow";
import { coreWebVitals } from "../../helpers/webVitals";
import { swrConfig } from "../config.generic";
import ProvidersInner from "./providersInner";

type Props = {
    children: React.ReactNode;
};

const Providers: React.FC<Props> = ({ children }) => {
    const searchParams = useSearchParams();
    // For webview we don't want to track web vitals.
    // Use search params as useIsWebview is declared in this component by ApplicationState provider.
    const initWebVitals = useRef(searchParams.get("webview") === "app");
    const window = getWindow();
    const pathname = usePathname();

    useEffect(() => {
        if (window) {
            pushDataLayer({
                type: "event",
                event: "savedata",
                eventCategory: "Save Data Check",
                eventAction:
                    "connection" in window.navigator &&
                    typeof window.navigator.connection.saveData !== "undefined"
                        ? window.navigator.connection.saveData
                        : "unsupported",
                eventLabel: window.navigator.userAgent,
                nonInteraction: true,
            });

            if (!initWebVitals.current) {
                coreWebVitals();
                initWebVitals.current = true;
            }
        }
    }, [window]);

    useEffect(() => {
        // this has to be done here, as the next Document is ssr only
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        window.newrelic?.setApplicationVersion(
            process.env.NEXT_PUBLIC_BUILD_NUMBER ?? "unknown"
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (typeof window?.newrelic?.addRelease !== "undefined") {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
            window.newrelic.addRelease(
                `web-app-react-${
                    process.env.NEXT_PUBLIC_DEPLOY_ENV ?? "unknown"
                }`,
                process.env.NEXT_PUBLIC_BUILD_NUMBER ?? "unknown"
            );
        }
    }, [window?.newrelic]);

    useEffect(() => {
        // exclude all radars and radar from snow pages
        if (
            window &&
            (!pathname.startsWith("/wetterkarten/") ||
                !pathname.includes("/schneeradar/"))
        ) {
            try {
                window.sessionStorage.setItem("wcom_internal_page", pathname);
            } catch (e) {
                sendError(e);
            }
        }
    }, [pathname, window]);

    return (
        <SWRConfig value={swrConfig}>
            <ApplicationState>
                <ConsentProvider>
                    <ProvidersInner>{children}</ProvidersInner>
                </ConsentProvider>
            </ApplicationState>
        </SWRConfig>
    );
};

export default Providers;
