"use client";
import React from "react";
import { cn } from "../../../helpers/classNames";
import { useOpenCMP } from "../../Consent/hooks";
import styles from "./styles.module.scss";

export const CmpLink: React.FC = () => {
    const openCMP = useOpenCMP();

    return (
        <li key="footer-cmp-link" className={styles.internalsLi}>
            <button
                data-label="Privatsphäre Einstellungen"
                aria-label="Einwilligungs-Fenster öffnen"
                title="Einwilligungs-Fenster öffnen"
                className={cn(styles.footerButton, "cmp-lnk-recall")}
                onClick={(): void => openCMP()}
                data-tracking-id="footer-open-cmp"
            >
                Einwilligungs-Fenster öffnen
            </button>
        </li>
    );
};
