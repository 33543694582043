"use client";

import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";
import times from "../../app/common/times";
import { pushDataLayer } from "../../helpers/dataLayer";
import { getWindow } from "../../helpers/getWindow";
import { sendError } from "../ErrorBoundary/helpers";
import { NewDataUpdateContent } from "./content";

const Lightbox = dynamic(
    () => import("../Lightbox").then((mod) => mod.Lightbox),
    { ssr: false }
);

export const NewDataUpdate: React.FC = () => {
    const window = getWindow();
    const searchParams = useSearchParams();
    const [showLightbox, setShowLightbox] = useState<boolean>(
        searchParams.get("forceNewDataLayer") === "true"
    );
    const frequency = 5; // in minutes

    useEffect(() => {
        const events = ["click", "scroll"];
        const setNewDataTimeout = (): void => {
            clearTimeout(idleTimeTimeout);
            idleTimeTimeout = setTimeout((): void => {
                pushDataLayer({
                    type: "event",
                    // disable eslint rule, because it would lead to typeof string, not typeof "ai~5MinutenLayer"
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    event: `ai~${frequency}MinutenLayer`,
                    eventCategory: "function",
                    eventAction: "show",
                    // disable eslint rule, because it would lead to typeof string, not typeof "ai~5MinutenLayer"
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    eventLabel: `${frequency}MinutenLayer`,
                });
                setShowLightbox(true);
            }, frequency * times["1_Minute"]);
        };
        let idleTimeTimeout: ReturnType<typeof setTimeout>;

        setNewDataTimeout();

        const resetNewDataTimeout = (): void => setNewDataTimeout();
        events.forEach((event) => {
            window?.addEventListener(event, resetNewDataTimeout, {
                passive: true,
            });
        });
        return (): void => {
            events.forEach((event) => {
                window?.removeEventListener(event, resetNewDataTimeout);
                clearTimeout(idleTimeTimeout);
            });
        };
    }, [window, frequency]);

    try {
        // Don't explicitly close the lightbox, to show loader inside lightbox for INP improvement.
        const reload = (): void => {
            pushDataLayer({
                type: "event",
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                event: `ae~${frequency}MinutenLayer`,
                eventCategory: "function",
                eventAction: "cancel",
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                eventLabel: `${frequency}MinutenLayer`,
            });
            window?.location.reload();
        };

        return showLightbox ? (
            <Lightbox
                isFullscreen
                callback={(): void => {
                    reload();
                }}
                zIndexKey="newDataUpdate"
                // as the page is reloaded on close, the PI is the new initial pagetrack after window.reload
                trackPageImpression="none"
                triggerAdReload="none"
                overlaySetTargeting="inactive_overlay"
                overlayType="inactive"
            >
                <NewDataUpdateContent reload={reload} />
            </Lightbox>
        ) : null;
    } catch (e) {
        sendError(e);
        return null;
    }
};
