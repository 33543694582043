import React from "react";
import { sendError } from "../ErrorBoundary/helpers";
import { SpinningSun } from "../SpinningSun";

type Props = {
    minHeight: number;
};

export const LoadingWrapper: React.FC<Props> = ({ minHeight }) => {
    try {
        return (
            <div style={{ minHeight: `${minHeight.toString()}px` }}>
                <SpinningSun size={minHeight > 44 ? 44 : minHeight} />
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
