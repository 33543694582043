import type { Nullable } from "../../../@types/generic";
import type { Coordinates } from "./coordinates";
import type { Media } from "./media";
import type { PlaylistItem } from "./playlistItem";
import type { Video } from "./video";

export type Livecam = {
    id: Nullable<string>;
    title: Nullable<string>;
    active: Nullable<boolean>;
    canonicalName: Nullable<string>;
    mappedLocation: {
        city: {
            code: Nullable<string>;
            title: Nullable<string>;
        };
        region: string;
        country: string;
        timezone: string;
    };
    additionalContent: Nullable<
        Array<{
            id: string;
            type: string;
        }>
    >;
    normalized: {
        title: string;
    };
    description: {
        short: Nullable<string>;
        long: Nullable<string>;
        extended: Nullable<string>;
    };
    coordinates: Coordinates & { elevation: Nullable<number> };
    thumbnails: { pattern: string }; // other patterns not supported for web-app-react
    partner: {
        id: Nullable<string>;
        canonicalName: Nullable<string>;
        name: Nullable<string>;
        isActive: Nullable<boolean>;
        trackingPixel: Nullable<string>;
    };
    partnerContent: {
        link: Nullable<string>;
        logo: Nullable<string>;
        name: Nullable<string>;
        template: Nullable<string>;
    };
    tags: Array<string>;
    dates: {
        created: Nullable<string>;
        modified: Nullable<string>;
    };
    upstreamId: string;
    media: Array<Media>;
    views: Nullable<number>;
    rating: {
        count: Nullable<number>;
        value: Nullable<number>;
        avg: Nullable<number>;
    };
    seo: {
        slug: Nullable<string>;
        title: Nullable<string>;
        description: Nullable<string>;
    };
    upcId: Nullable<string>;
    meta: Nullable<{
        distance: Nullable<number>;
    }>;
};

export type LivecamsPaginated = {
    items: Array<Livecam>;
    meta: {
        totalCount: number;
        limit: number;
        offset: number;
    };
};

export const isLivecam = (
    mediaObject: Video | Livecam | PlaylistItem
): mediaObject is Livecam =>
    mediaObject.hasOwnProperty("upcId") &&
    mediaObject.hasOwnProperty("upstreamId") &&
    !mediaObject.hasOwnProperty("duration");
