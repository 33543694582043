import React, { useState } from "react";
import { getRoute } from "../../helpers/urls/getRoute";
import { AdSlot } from "../Ad/slots";
import { useCountry } from "../ApplicationState/hooks";
import { Button } from "../Button";
import { sendError } from "../ErrorBoundary/helpers";
import { LoadingWrapper } from "../LoadingWrapper";
import styles from "./content.module.scss";

type NewDataUpdatePopupProps = {
    reload: () => void;
};

export const NewDataUpdateContent: React.FC<NewDataUpdatePopupProps> = ({
    reload,
}: NewDataUpdatePopupProps) => {
    const country = useCountry();
    const [isLoading, setIsLoading] = useState(false);
    const newUpdateLayer = {
        de: {
            title: "Zum aktuellen Deutschlandwetter",
            description: "zum aktuellen Deutschlandwetter",
        },
        at: {
            title: "Zum aktuellen Österreichwetter",
            description: "zum aktuellen Österreichwetter",
        },
        ch: {
            title: "Zum aktuellen Schweizwetter",
            description: "zum aktuellen Schweizwetter",
        },
        pl: {
            title: "", // TODO: Add title
            description: "", // TODO: Add description
        },
    };

    try {
        return (
            <div className={styles.wrapper}>
                <div className={styles.dialog}>
                    <span className={styles.warningIcon}>
                        <span className="icon-notice" />
                    </span>
                    <p className={styles.message}>
                        Es liegen neue Wetterdaten
                        <br />
                        für Sie vor.
                    </p>
                    <Button
                        className={styles.reload}
                        variant={isLoading ? "secondary" : "primary"}
                        fullWidth={true}
                        onClick={() => {
                            setIsLoading(true);
                            reload();
                        }}
                        data-action="confirm"
                    >
                        {isLoading ? (
                            <LoadingWrapper minHeight={20} />
                        ) : (
                            <span>Jetzt Daten aktualisieren</span>
                        )}
                    </Button>
                    <a
                        className={styles.link}
                        href={getRoute({
                            parameters: {
                                pageName: "video",
                            },
                            country,
                        })}
                        aria-label={newUpdateLayer[country].title}
                        title={newUpdateLayer[country].title}
                    >
                        {newUpdateLayer[country].description}
                        <span className="icon-arrow-link" />
                    </a>
                </div>
                <div className={styles.adWrapper}>
                    <AdSlot
                        slot={{
                            de: "wetter_vorhersage_performancebanner3",
                            at: "WET_AT_M_performancebanner3",
                            ch: "WET_CH_M_performancebanner3",
                            pl: undefined,
                        }}
                        minHeight="250px"
                    />
                </div>
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
